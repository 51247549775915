/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

a {
  text-decoration: none;
}

/* Login Container */
.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #6e7cfc, #f9c0c7);
}

.login-card {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-heading {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #333;
}

/* Input Fields */
.input-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-field {
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.input-field:focus {
  border-color: #6e7cfc;
}

.input-field::placeholder {
  color: #888;
}

/* Submit Button */
.login-btn {
  padding: 15px;
  font-size: 18px;
  background-color: #6e7cfc;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #5a67d8;
}

/* Footer Links */
.footer-links {
  margin-top: 20px;
}

.forgot-password-link {
  color: #6e7cfc;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-password-link:hover {
  color: #4a56cc;
}
