/* General Styles */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f5;
  color: #333;
  overflow-x: hidden;
}

/* General Container */
.portfolio-container {
  width: 100%;
  padding: 0;
}

/* Header */
.header {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.navbar .logo {
  font-size: 26px;
  font-weight: bold;
  color: #333;
  letter-spacing: 1px;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-left: 40px;
}

.nav-links a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #007aff;
}

/* Hero Section */
.hero-section {
  background-image: url('https://images.unsplash.com/photo-1517430816045-df4b7de4ac2d?crop=entropy&cs=tinysrgb&fit=max&ixid=MnwzNjU2OXwwfDF8c2VhY2h8MXx8Y29kaW5nJTIwY3VsdHVyZXxlbnwwfDB8fHwyfHw%3D&ixlib=rb-1.2.1&q=80&w=1080');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 100px 20px;
  text-align: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.hero-section h1 {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.hero-section p {
  font-size: 20px;
  margin-bottom: 40px;
  opacity: 0.8;
}

.hero-section .btn-primary {
  background-color: #007aff;
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.hero-section .btn-primary:hover {
  background-color: #005f94;
  transform: translateY(-5px);
}

/* About Section */
.about-section {
  padding: 80px 50px;
  text-align: center;
  background-color: #fff;
}

.about-section h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.about-section p {
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  opacity: 0.8;
}

/* Skills Section */
.skills-section {
  padding: 80px 50px;
  text-align: center;
  background-color: #f9f9f9;
}

.skills-section h2 {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 600;
}

.skills-section ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills-section li {
  font-size: 18px;
  margin: 10px 30px;
  font-weight: 500;
  color: #333;
  transition: color 0.3s ease;
}

.skills-section li:hover {
  color: #007aff;
}

/* Projects Section */
.projects-section {
  background-color: #fff;
  padding: 80px 50px;
}

.projects-section h2 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
}

.project-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project-item {
  background-color: #f4f4f4;
  padding: 30px;
  width: 48%;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.project-item:hover {
  transform: translateY(-10px);
}

.project-item h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.project-item p {
  font-size: 16px;
  line-height: 1.5;
  opacity: 0.8;
}

/* Contact Section */
.contact-section {
  background-color: #f9f9f9;
  padding: 80px 50px;
  text-align: center;
}

.contact-section h2 {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 600;
}

.contact-section p {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-section a {
  color: #007aff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.contact-section a:hover {
  text-decoration: underline;
}

/* Footer Section */
.footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer p {
  font-size: 16px;
}

/* Log Out Button */
.logout-btn {
  background-color: #ff5722;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-btn:hover {
  background-color: #e64a19;
  transform: translateY(-5px);
}

/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
  .nav-links {
    display: block;
    text-align: center;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .project-list {
    flex-direction: column;
  }

  .project-item {
    width: 100%;
  }

  .hero-section {
    padding: 120px 20px;
  }

  .hero-section h1 {
    font-size: 35px;
  }

  .hero-section p {
    font-size: 18px;
  }
}
